import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
const NotFound = dynamic(() => import('../components/NotFound'))
// helpers
import { apiHelper, apiHelperBlockchain, apiHelperWordpressForNews } from '../helper/apiHelper';

export default function NotFoundClass(props) {

  // get route
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === "/404") {
      document.body.classList.add("notFoundPage");
    }
  }, [router])

  return (
    <NotFound currLocale={props.currLocale} />
  )
}

export const getStaticProps = async ({ locale }, ctx) => {

  var postData = {
    language: locale,
    pageName: 'page404'
  }

  const resMeta = await apiHelper(`metadata/getNftMetadata`, "POST", postData, null);
  const meta = resMeta.data.status ? resMeta.data.data : null;

  const resCatList = await apiHelperBlockchain(`category-list`, "GET", null, null);
  const catList = resCatList.data.status ? resCatList.data.data : null;
  
  let catQuery = `query NewQuery {
        ${locale === 'en' ? 'allNewsMenuEN' : 'allNewsMenuAR'} {
          nodes {
            name
            slug
            newsMenu {
              order
              domainSlug
              label
            }
          }
        }
      }`;
  const resCategory = await apiHelperWordpressForNews(catQuery);
  const category = (locale === 'en' ? resCategory.data.data.allNewsMenuEN : resCategory.data.data.allNewsMenuAR).nodes;
  let categories = category && category.length > 0 && category.map(tag => {
    return { ...tag, order: tag.newsMenu.order, domainSlug: tag.newsMenu.domainSlug, label: tag.newsMenu.label }
  })
  categories.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

  let tagNewsQuery = `query NewQuery {
        ${locale === 'en' ? 'allNewsTagsEN' : 'allNewsTagsAR'}(first: 1000) {
          nodes {
            name
            slug
            news_tags_fields {
              popularTags
              topTags
            }
          }
        }
      }`
  const resTagNews = await apiHelperWordpressForNews(tagNewsQuery);
  let tagsList = (locale === 'en' ? resTagNews.data.data.allNewsTagsEN : resTagNews.data.data.allNewsTagsAR).nodes;
  let newsPopularTags = []
  if (tagsList && tagsList.length > 0) {
    tagsList.map((info) => {
      if (info.news_tags_fields.popularTags) {
        delete info.news_tags_fields
        newsPopularTags.push(info)
      }
    })
  }

  return {
    props: {
      meta,
      currLocale: locale == 'default' ? 'en' : locale,
      catList,
      category: categories,
      newsPopularTags,
      ...(await serverSideTranslations(locale, ['common']))
    },
    revalidate: 10, // In seconds
  }
}